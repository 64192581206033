.grid-item {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s;
    background: white;
}

.grid-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.card-image-container {
    position: relative;
    height: 200px;
    overflow: hidden;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 600px) {
    .price-badge {
        font-size: calc(0.7em + 0.5vw);
        padding: 4px 8px;
        top: 8px;
        right: 8px;
    }
}

.price-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 6px 12px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 0.9em;
}

.price-badge.cheaper {
    background: #ff4757;
}

.price-badge.price_drop {
    background: #2ed573;
}

.card-content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.destination {
    margin: 0 0 8px 0;
    font-size: 1.4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}

.departure, .arrival {
    flex: 1;
    text-align: center;
}

.arrow {
    font-size: 1.2em;
    color: #666;
    margin: 0 10px;
}

.country {
    display: block;
    font-size: 0.8em;
    color: #666;
    font-weight: normal;
}

.airline-name {
    width: 100%;
    text-align: center;
    font-size: 0.85rem;
    color: #666;
    margin-top: 8px;
}

.dates {
    color: #666;
    margin-bottom: 8px;
}

.price-section {
    margin: 8px 0;
}

.price-alert {
    text-align: center;
}

.current-price {
    font-size: 1.8em;
    font-weight: bold;
    color: #2ed573;
}

.price-comparison {
    margin-top: 4px;
    font-size: 0.9em;
    color: #666;
}

.savings {
    margin-left: 8px;
    color: #2ed573;
    font-weight: bold;
}

.detail-button {
    width: 100%;
    padding: 10px;
    background: #1e90ff;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.2s;
    margin-top: auto;
}

.detail-button:hover {
    background: #1873cc;
} 