.contact-us {
  padding: 2rem;
  background-color: #f8f9fa;
}

.contact-content {
  max-width: 1200px;
  margin: 0 auto;
}

.page-contact {
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.footer-contact {
  padding: 1rem;
  background-color: #333;
  color: white;
  width: 100%;
}

.footer-contact .contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-info {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
  justify-content: center;
}

.contact-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-details h3 {
  margin-bottom: 1.5rem;
  color: #333;
}

.footer-contact .contact-details h3 {
  color: white;
}

.contact-details p {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contact-details a {
  color: inherit;
  text-decoration: none;
}

.contact-details a:hover {
  color: #007bff;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  justify-content: center;
}

.social-links a {
  font-size: 1.2rem;
  color: #666;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-contact .social-links a {
  color: white;
}

.social-links a:hover {
  color: #007bff;
}

.contact-form {
  flex: 2;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.success-message {
  color: #28a745;
  margin-top: 1rem;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
  }
  
  .contact-form {
    padding: 1rem;
  }
} 