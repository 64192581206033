.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/*.App-header {*/
/*    background: #007bff;*/
/*    color: white;*/
/*    padding: 15px;*/
/*    display: flex;*/
/*    !*justify-content: space-between;*!*/
/*    !*align-items: center;*!*/
/*    justify-content: space-between;*/
/*}*/

/* Hero Section with Full-width Background Image */
.App-header {
    background-image: url('assets/Capadoccia.webp'); /* Replace with your image */
    background-size: cover;
    background-position: center;
    height: 200px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

.navbar {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    background-color: #333;
}

.navbar-links {
    display: flex;
    gap: 1rem;
}

.navbar-links a {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem;
}

.navbar-links a:hover {
    color: #ddd;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f3f3f3;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px;
}

.grid-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.price-new {
    /*font-size: 1.5em;*/
    font-weight: bold;
    color: green;
    margin: 10px 0;
}

.item-title {
    padding: 10px;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    background-color: #3498db;
    color: white;
}

.item-image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

.item-description {
    padding: 10px;
    font-size: 0.9em;
    color: #555;
    text-align: center;
    flex-grow: 1;
}

@media (max-width: 600px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr); /* Single column on smaller screens */
    }
}


.flight_details {
    width: 60%; /* Set the width to less than 100% (e.g., 60%) */
    margin: 0 auto; /* Centers the div horizontally */
    padding: 20px; /* Optional: Add some padding */
    background-color: #f0f0f0; /* Optional: Add a background color */
    text-align: center; /* Optional: Center the content inside */
}


.detail-button-container {
    display: flex; /* Use flexbox to align buttons in a row */
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px; /* Optional: Add space between buttons */
    margin: 20px; /* Optional: Add some margin around the container */
}

.detail-option-button {
    padding: 10px 20px; /* Add padding to the buttons */
    font-size: 16px; /* Set a comfortable font size */
    cursor: pointer; /* Show pointer on hover */
}

input[type="text"] {
    width: 20%;
    padding: 10px;
    /*margin: 20px 5;*/
    font-size: 1rem;
    justify-content: left;
}

@media (max-width: 600px) {
    input[type="text"] {
        width: 80%; /* Wider search box on mobile */
        font-size: 0.9rem;
        margin: 10px 0;
    }

    .pagination {
        /*flex-direction: column;*/
        align-items: center;
    }

    .pagination h3 {
        font-size: 1rem;
        margin: 5px 0;
    }

    .pagination button {
        background-color: #007bff;
        color: white;
        padding: 5px 8px;
        font-size: 0.9rem;
        margin: 2px;
        border-radius: 4px;
        cursor: pointer;
    }

    .pagination button.active {
        background-color: #0056b3;
    }
}

@media (min-width: 601px) {
    input[type="text"] {
        width: 40%;
        font-size: 0.95rem;
    }

    .pagination {
        justify-content: center;
        margin-bottom: 10px;
    }

    .pagination h3 {
        font-size: 1.1rem;
    }

    .pagination button {
        background-color: #007bff;
        color: white;
        padding: 6px 9px;
        font-size: 0.95rem;
        border-radius: 4px;
        cursor: pointer;
    }

    .pagination button.active {
        background-color: #0056b3;
    }
}


/* Style for the div content */
.content {
    margin-top: 20px;
    padding: 15px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.login-button {
    width: 10%;
    padding: 5px;
    background: #2575fc;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 8px;
    transition: background 0.3s;
}

.toggle-text {
    text-align: center;
    margin-top: 10px;
}

.toggle-text span {
    color: #2575fc;
    cursor: pointer;
    font-weight: bold;
}
